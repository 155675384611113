<template>
  <div class="cost-modi-pop common-pop">
    <el-dialog
      :title="todo == 'view' ? '【查看】订单费用信息' : '【编辑】订单费用信息'"
      :close-on-click-modal="false"
      width="60%"
      :visible.sync="visible">
      <div class="modularLabel modularLabel1">
        <label><span class="separate">|</span>订单信息</label>
      </div>
      <div class="modularBox modularBox1">
        <el-form :model="dataForm" ref="dataForm" label-width="100px" size="mini">
          <el-row :gutter="10">
            <el-col :span="6">
              <el-form-item label="订单编号">
                <el-input v-model="dataForm.orderNo" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="订单类型">
                <el-select class="item-choose" v-model="dataForm.orderType" :disabled="true" style="width: 100%;">
                  <el-option v-for="item in dictTypeMap.orderType" :key="item.id" :label="item.name" :value="item.id" ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="订单车长(米)">
                <el-select class="item-choose" v-model="dataForm.vehicleType" :disabled="true" style="width: 100%;">
                  <el-option v-for="item in dictTypeMap.vehicle_type" :key="item.id" :label="item.name" :value="item.id" ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="订单车型">
                <el-select class="item-choose" v-model="dataForm.vehicleModelIdList" multiple :disabled="true" style="width: 100%;" collapse-tags>
                  <el-option
                    v-for="item in dictTypeMap.vehicle_model"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="6">
              <el-form-item label="提货时间">
                <el-input v-model="dataForm.takeGoodsDate" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="客户名称">
                <el-input v-model="dataForm.customerName" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
<!--            <el-col :span="6" v-if="chargeType">-->
<!--              <el-form-item label="客户业务员">-->
<!--                <el-input v-model="dataForm.customerBusinessName" :disabled="true"></el-input>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
            <el-col :span="6">
              <el-form-item label="进仓单号">
                <el-input v-model="dataForm.inWarehouseNo" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="订单状态">
                <el-input v-model="dataForm.status" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="0" v-for="(item) in dataForm.addressList" :key="item.id">
            <el-col :span="24">
              <el-form-item :label="item.type === 1 ? '提货方' : item.type === 2 ? '卸货方' : '报关方'">
                <el-col :span="8">
                  <el-input v-model="item.factoryName" :disabled="true"></el-input>
                </el-col>
                <el-col :span="16">
                  <el-input v-model="item.fullAddress" :disabled="true"></el-input>
                </el-col>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="6">
              <el-form-item label="司机车牌">
                <el-input v-model="dataForm.vehiclePlan.vehiclePlate" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="司机姓名">
                <el-input v-model="dataForm.vehiclePlan.driverName" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="司机电话">
                <el-input v-model="dataForm.vehiclePlan.driverPhone" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="车次号">
                <el-input v-model="dataForm.vehiclePlan.planNo" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="6">
              <el-form-item label="件数">
                 <el-input v-model="dataForm.orderGoods.pieceNum" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="重量">
                 <el-input v-model="dataForm.orderGoods.weight" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="体积">
                 <el-input v-model="dataForm.orderGoods.volume" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
              <el-col :span="6">
                <el-form-item label="客户单号">
                  <el-input v-model="dataForm.customerSelfNo" :disabled="true"></el-input>
                </el-form-item>
              </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="6">
              <el-form-item label="客户联系人">
                <el-input v-model="dataForm.customerContactName" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="客户联系电话">
                <el-input v-model="dataForm.customerContactPhone" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="下单时间">
                <el-input v-model="dataForm.commitTime" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="下单人">
                <el-input v-model="dataForm.commitName" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="12">
              <el-form-item label="订单备注">
                <el-input v-model="dataForm.remark" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="异常备注">
                <el-input v-model="dataForm.groupExplain" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="24">
              <el-form-item label="客服备注">
                <el-input v-model="dataForm.companyRemark" :disabled="true"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="50">
            <el-col :span="24">
              <el-steps :active="statusActive" style="margin-left: 20px;" finish-status="success">
                <el-step title="派车时间" :description="dispatchTime"></el-step>
                <el-step title="出车时间" :description="outDriveTime"></el-step>
                <el-step title="装货到场时间" :description="arrivedTime"></el-step>
                <el-step title="装货离场时间" :description="loadGoodsTime"></el-step>
                <el-step title="卸货到场时间" :description="arrivedGiveTime"></el-step>
                <el-step title="签收时间" :description="successTime"></el-step>
              </el-steps>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="modularLabel modularLabel2">
        <label><span class="separate">|</span>客户费用（应收）</label>
        <label>装货图片<span @click="preview(loadGoodsTypeList,dataForm.id)" style="color: blue;cursor:pointer">预览</span></label>
        <label>签收单<span @click="preview(successTypeList,dataForm.id)" style="color: blue;cursor:pointer">预览</span></label>
<!--        <div class="modularLabel modularLabel2">-->
<!--          <label>装货图片<span @click="preview(loadGoodsTypeList)" style="color: blue;cursor:pointer">预览</span></label>-->
<!--          <div class="modularLabel modularLabel2">-->
<!--            <label>签收单<span @click="preview(successTypeList)" style="color: blue;cursor:pointer">预览</span></label>-->
<!--          </div>-->
<!--        </div>-->
      </div>
      <div class="modularBox modularBox2">
        <el-form :model="costInfo" ref="dataForm" label-width="100px" :rules="rules" size="mini">
          <el-row :gutter="10">
            <el-col :span="6">
              <el-form-item v-if="this.isFreightFeeShow" label="金额小计" prop="totalMoney">
                <el-input v-model="costInfo.totalMoney" readonly="readonly"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item v-if="this.isFreightFeeShow" label="运费"  prop="freightFee">
                <el-input v-model="costInfo.freightFee" @input="countMoney(1, 'freightFee')" :disabled="isViews || costInfo.status === 2" placeholder="运费"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="押夜超时费" prop="overtimeFee">
                <el-input v-model="costInfo.overtimeFee" @input="countMoney(1, 'overtimeFee')" :disabled="isViews || costInfo.status === 2" placeholder="押夜超时费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.overtimeFee, costInfo.id)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="加点费" prop="addFee">
                <el-input v-model="costInfo.addFee" @input="countMoney(1, 'addFee')" :disabled="isViews || costInfo.status === 2" placeholder="加点费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.addFee, costInfo.id)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="6">
              <el-form-item label="过磅费" prop="weighFee">
                <el-input v-model="costInfo.weighFee" @input="countMoney(1)" :disabled="isViews || costInfo.status === 2" placeholder="过磅费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.weighFee, costInfo.id)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="装卸费" prop="loadFee">
                <el-input v-model="costInfo.loadFee" @input="countMoney(1)" :disabled="isViews || costInfo.status === 2" placeholder="装卸费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.loadFee, costInfo.id)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="报关/入仓费" prop="entranceFee">
                <el-input v-model="costInfo.entranceFee" @input="countMoney(1)" :disabled="isViews || costInfo.status === 2" placeholder="报关/入仓费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.entranceFee, costInfo.id)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="提货费" prop="takeFee">
                <el-input v-model="costInfo.takeFee" @input="countMoney(1)" :disabled="isViews || costInfo.status === 2" placeholder="提货费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.takeFee, costInfo.id)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="6">
              <el-form-item label="停车费" prop="parkingFee">
                <el-input v-model="costInfo.parkingFee" @input="countMoney(1)" :disabled="isViews || costInfo.status === 2" placeholder="停车费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.parkingFee, costInfo.id)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="高速费" prop="highFee">
                <el-input v-model="costInfo.highFee" @input="countMoney(1)" :disabled="isViews || costInfo.status === 2" placeholder="高速费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.highFee, costInfo.id)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="其他费用" prop="otherFee">
                <el-input v-model="costInfo.otherFee" @input="countMoney(1)" :disabled="isViews || costInfo.status === 2" placeholder="其他费用">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.otherFee, costInfo.id)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="其他费用备注" prop="otherFeeRemark">
                <el-input v-model="costInfo.otherFeeRemark" :disabled="isViews || costInfo.status === 2" placeholder="其他费用备注"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="modularLabel modularLabel2">
        <label v-if="allWinVehicle"><span class="separate">|</span>成本费用（应付/共赢）</label>
        <label v-else><span class="separate">|</span>成本费用（应付）</label>
      </div>
      <div class="modularBox modularBox2">
        <el-form  :model="payCostInfo" ref="dataForm" label-width="100px" :rules="payRules" size="mini">
          <el-row  :gutter="10">
            <el-col :span="6">
              <el-form-item v-if="this.isFreightFeeShow" label="金额小计" prop="auditTotalMoney">
                <el-input v-model="payCostInfo.auditTotalMoney" readonly="readonly"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item v-if="this.isFreightFeeShow"  label="运费" prop="freightFee">
                <el-input v-model="payCostInfo.freightFee" @input="countMoney(2)" :disabled="isViews || payCostInfo.payRequestConfig.freightFeeBox || payCostInfo.status === 2" placeholder="运费"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="押夜超时费" prop="overtimeFee">
                <el-input v-model="payCostInfo.overtimeFee" @input="countMoney(2)" :disabled="isViews || payCostInfo.payRequestConfig.overtimeFeeBox || payCostInfo.status === 2" placeholder="押夜超时费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.overtimeFee, payCostInfo.id)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="加点费" prop="addFee">
                <el-input v-model="payCostInfo.addFee" @input="countMoney(2)" :disabled="isViews || payCostInfo.payRequestConfig.addFeeBox || payCostInfo.status === 2" placeholder="加点费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.addFee, payCostInfo.id)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="6">
              <el-form-item label="过磅费" prop="weighFee">
                <el-input v-model="payCostInfo.weighFee" @input="countMoney(2)" :disabled="isViews || payCostInfo.payRequestConfig.weighFeeBox || payCostInfo.status === 2" placeholder="过磅费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.weighFee, payCostInfo.id)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="代支装卸费" prop="loadFee">
                <el-input v-model="payCostInfo.loadFee" @input="countMoney(2, 'loadFee')" :disabled="isViews || payCostInfo.payRequestConfig.loadFeeBox || payCostInfo.status === 2" placeholder="代支装卸费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.loadFee, payCostInfo.id)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="报关/入仓费" prop="entranceFee">
                <el-input v-model="payCostInfo.entranceFee" @input="countMoney(2)" :disabled="isViews || payCostInfo.payRequestConfig.entranceFeeBox || payCostInfo.status === 2" placeholder="报关/入仓费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.entranceFee, payCostInfo.id)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="停车费" prop="parkingFee">
                <el-input v-model="payCostInfo.parkingFee" @input="countMoney(2)" :disabled="isViews || payCostInfo.payRequestConfig.parkingFeeBox || payCostInfo.status === 2" placeholder="停车费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.parkingFee, payCostInfo.id)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
<!--            <el-col :span="6">-->
<!--              <el-form-item label="司机装卸费" prop="takeFee">-->
<!--                <el-input v-model="payCostInfo.takeFee" @input="countMoney(2)" :disabled="isViews || payCostInfo.payRequestConfig.takeFeeBox" placeholder="司机装卸费">-->
<!--                  <el-button slot="append" type="text" @click="preview(imgTypeList.takeFee, payCostInfo.id)">预览</el-button>-->
<!--                </el-input>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
          </el-row>
          <el-row :gutter="10">

            <el-col :span="6">
              <el-form-item label="高速费" prop="highFee">
                <el-input v-model="payCostInfo.highFee" @input="countMoney(2)" :disabled="isViews || payCostInfo.payRequestConfig.highFeeBox || payCostInfo.status === 2" placeholder="高速费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.highFee, payCostInfo.id)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="打印费" prop="printFee">
                <el-input v-model="payCostInfo.printFee" @input="countMoney(2)" :disabled="isViews || payCostInfo.payRequestConfig.printFeeBox || payCostInfo.status === 2" placeholder="打印费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.printFee, payCostInfo.id)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="维修费" prop="maintainFee">
                <el-input v-model="payCostInfo.maintainFee" @input="countMoney(2)" :disabled="isViews || payCostInfo.payRequestConfig.maintainFeeBox || payCostInfo.status === 2" placeholder="加点费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.maintainFee, payCostInfo.id)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="现金加油费" prop="refuelFee">
                <el-input v-model="payCostInfo.refuelFee" @input="countMoney(2)" :disabled="isViews || payCostInfo.payRequestConfig.refuelFeeBox || payCostInfo.status === 2" placeholder="现金加油费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.refuelFee, payCostInfo.id)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="6">
              <el-form-item label="现金过路费" prop="passingFee">
                <el-input v-model="payCostInfo.passingFee" @input="countMoney(2)" :disabled="isViews || payCostInfo.payRequestConfig.passingFeeBox || payCostInfo.status === 2" placeholder="现金过路费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.passingFee, payCostInfo.id)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="油卡费" prop="refuelCardFee">
                <el-input v-model="payCostInfo.refuelCardFee" @input="countMoney(2)" :disabled="isViews || payCostInfo.status === 2" placeholder="油卡费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.refuelCardFee, payCostInfo.id)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="粤通卡费" prop="passingCardFee">
                <el-input v-model="payCostInfo.passingCardFee" @input="countMoney(2)" :disabled="isViews || payCostInfo.status === 2" placeholder="粤通卡费">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.passingCardFee, payCostInfo.id)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="其他费用" prop="otherFee">
                <el-input v-model="payCostInfo.otherFee" @input="countMoney(2)" :disabled="isViews || payCostInfo.payRequestConfig.otherFeeBox || payCostInfo.status === 2" placeholder="其他费用">
                  <el-button slot="append" type="text" @click="preview(imgTypeList.otherFee, payCostInfo.id)">预览</el-button>
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">

            <el-col :span="6">
              <el-form-item label="平台代垫" prop="platformPrepayment">
                <el-input v-model="payCostInfo.platformPrepayment" :disabled="true" placeholder="平台预付代垫"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="司机代垫" prop="driverPrepayment">
                <el-input v-model="payCostInfo.driverPrepayment" :disabled="true" placeholder="司机预付代垫"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="其他费用备注" prop="otherFeeRemark">
                <el-input v-model="payCostInfo.otherFeeRemark" :disabled="isViews || payCostInfo.status === 2" placeholder="其他费用备注"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="6">
              <el-form-item label="司机借支" prop="driverBorrowing">
                <el-input v-model="payCostInfo.driverBorrowing" :disabled="isViews || payCostInfo.payRequestConfig.driverBorrowingBox || payCostInfo.status === 2">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="扣款" prop="deductionsFee">
                <el-input v-model="payCostInfo.deductionsFee" :disabled="isViews || payCostInfo.payRequestConfig.deductionsFeeBox || payCostInfo.status === 2">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="借支/扣款备注" prop="borrowingRemark">
                <el-input v-model="payCostInfo.borrowingRemark"  :disabled="isViews || payCostInfo.status === 2" placeholder="个体/合作车队费用借支/油卡/扣款录入">
                </el-input>
              </el-form-item>
            </el-col>
<!--            <el-col :span="6">-->
<!--              <el-form-item label="扣款备注" prop="deductionsRemark">-->
<!--                <el-input v-model="payCostInfo.deductionsRemark" :disabled="isViews" placeholder="扣款备注">-->
<!--                </el-input>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
          </el-row>
        </el-form>
      </div>

      <div class="modularLabel modularLabel2" v-if="showDriverRoyalty && !allWinVehicle">
        <label><span class="separate">|</span>司机提成费用</label>
      </div>
      <div class="modularBox modularBox2" v-if="showDriverRoyalty && !allWinVehicle">
        <el-form :model="driverRoyalty" ref="dataForm" label-width="100px" :rules="driverRules" size="mini">
          <el-row :gutter="10">
            <el-col :span="6">
              <el-form-item v-if="this.isFreightFeeShow"  label="应收运费" prop="freightFee">
                <el-input v-model="driverRoyalty.freightFee" @input="countBaseMoney()" placeholder="应收运费"
                          :disabled="isViews || !royaltyFeeBox">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="压夜超时费" prop="overtimeFee">
                <el-input v-model="driverRoyalty.overtimeFee" @input="countBaseMoney()" placeholder="压夜超时费"
                          :disabled="isViews || !royaltyFeeBox">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="加点费" prop="addFee">
                <el-input v-model="driverRoyalty.addFee" @input="countBaseMoney()" placeholder="加点费"
                          :disabled="isViews || !royaltyFeeBox">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="司机提成费" prop="royaltyFee">
                <el-input v-model="driverRoyalty.royaltyFee" :disabled="true">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="6">
              <el-form-item label="装卸费" prop="loadFee">
                <el-input v-model="driverRoyalty.loadFee" @input="calculateRoyaltyTotal" :disabled="isViews || driverRoyalty.payRequestConfig.loadFeeBox" placeholder="装卸费">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="装卸费备注" prop="loadFeeRemark">
                <el-input v-model="driverRoyalty.loadFeeRemark" :disabled="isViews" placeholder="装卸费备注">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="加班费" prop="extraFee">
                <el-input v-model="driverRoyalty.extraFee" @input="calculateRoyaltyTotal" :disabled="isViews || driverRoyalty.payRequestConfig.extraFeeBox" placeholder="加班费">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="住宿费" prop="stayFee">
                <el-input v-model="driverRoyalty.stayFee" @input="calculateRoyaltyTotal" :disabled="isViews || driverRoyalty.payRequestConfig.stayFeeBox" placeholder="住宿费">
                </el-input>
              </el-form-item>
            </el-col>
<!--            <el-col :span="6">-->
<!--              <el-form-item label="基本工资/全勤" prop="payFreightFee">-->
<!--                <el-input v-model="driverRoyalty.payFreightFee" :disabled="isViews">-->
<!--                </el-input>-->
<!--              </el-form-item>-->
<!--            </el-col>-->
          </el-row>
          <el-row :gutter="10">
            <el-col :span="6">
              <el-form-item label="工资基数" prop="wageBase">
                <el-input v-model="wageBase" :disabled="isViews" @input="calculateReward" placeholder="输入计算基本工资">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="基本工资" prop="baseFee">
                <el-input v-model="driverRoyalty.baseFee" @input="calculateRoyaltyTotal" :disabled="isViews || driverRoyalty.payRequestConfig.baseFeeBox" placeholder="基数 * 基本工资">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="绩效工资" prop="meritsFee">
                <el-input v-model="driverRoyalty.meritsFee" @input="calculateRoyaltyTotal" :disabled="isViews || driverRoyalty.payRequestConfig.meritsFeeBox" placeholder="基数 * 绩效工资">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="使用APP奖励" prop="appFee">
                <el-input v-model="driverRoyalty.appFee" @input="calculateRoyaltyTotal" :disabled="isViews || driverRoyalty.payRequestConfig.appFeeBox" placeholder="基数 * APP奖励">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="6">
              <el-form-item label="租房费" prop="other1Fee">
                <el-input v-model="driverRoyalty.other1Fee" @input="calculateRoyaltyTotal" :disabled="isViews || driverRoyalty.payRequestConfig.other1FeeBox" placeholder="基数 * 其他奖励1">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="油耗奖励" prop="other2Fee">
                <el-input v-model="driverRoyalty.other2Fee" @input="calculateRoyaltyTotal" :disabled="isViews || driverRoyalty.payRequestConfig.other2FeeBox" placeholder="基数 * 其他奖励2">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="司机费用合计"  prop="payableAmount" class="item">
                <el-input v-model="driverRoyalty.payableAmount" :disabled="true" placeholder="司机提成+装卸+加班+住宿+基本+绩效+APP+其他1+其他2">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="请假扣款" prop="accumulationFund">
                <el-input v-model="driverRoyalty.accumulationFund" @input="calculateRoyaltyTotal" :disabled="isViews || driverRoyalty.payRequestConfig.accumulationFundBox" placeholder="请假扣款">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="6">
              <el-form-item label="扣减安全奖" prop="safetyAward">
                <el-input v-model="driverRoyalty.safetyAward" @input="calculateRoyaltyTotal" :disabled="isViews || driverRoyalty.payRequestConfig.safetyAwardBox" placeholder="扣减安全奖">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="扣减违章/油耗" prop="oilConsumption">
                <el-input v-model="driverRoyalty.oilConsumption" @input="calculateRoyaltyTotal" :disabled="isViews || driverRoyalty.payRequestConfig.oilConsumptionBox" placeholder="扣减违章/油耗">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="分摊水电费" prop="hydropower">
                <el-input v-model="driverRoyalty.hydropower" :disabled="isViews || driverRoyalty.payRequestConfig.hydropowerBox" placeholder="分摊水电费">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="个税" prop="personalIncomeTax">
                <el-input v-model="driverRoyalty.personalIncomeTax" @input="calculateRoyaltyTotal" :disabled="isViews || driverRoyalty.payRequestConfig.personalIncomeTaxBox" placeholder="个税">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="6">
              <el-form-item label="其他费用" prop="otherExpenses">
                <el-input v-model="driverRoyalty.otherExpenses" @input="calculateRoyaltyTotal" :disabled="isViews || driverRoyalty.payRequestConfig.otherExpensesBox" placeholder="其他费用">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="扣社保/公积金" prop="deductionsFee">
                <el-input v-model="driverRoyalty.deductionsFee" :disabled="isViews || driverRoyalty.payRequestConfig.deductionsFeeBox" placeholder="社保扣款">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="司机借支" prop="driverBorrowing">
                <el-input v-model="driverRoyalty.driverBorrowing" :disabled="isViews || driverRoyalty.payRequestConfig.driverBorrowingBox" placeholder="司机借支">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="借支/扣款备注" prop="remark">
                <el-input v-model="driverRoyalty.remark" type="textarea" :disabled="isViews" placeholder="社保扣款/出险扣款说明">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="modularLabel modularLabel2">
        <label><span class="separate">|</span>供应商费用</label>
      </div>
      <div class="modularBox modularBox2">
        <el-form :model="supplierCost" ref="dataForm" label-width="100px" size="mini">
          <el-row :gutter="10">
            <el-col :span="6">
              <el-form-item v-if="this.isFreightFeeShow" label="运费" prop="freightFee">
                <el-input v-model="supplierCost.freightFee" placeholder="运费"
                          :disabled="isViews">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="其他费" prop="otherFee">
                <el-input v-model="supplierCost.otherFee" placeholder="其他费"
                          :disabled="isViews">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="收款人" prop="payeeUser">
                <el-input v-model="supplierCost.payeeUser" placeholder="收款人"
                          :disabled="isViews">
                </el-input>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="收款人电话" prop="payeePhone">
                <el-input v-model="supplierCost.payeePhone"  placeholder="收款人电话" :disabled="isViews">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row :gutter="10">
            <el-col :span="24">
              <el-form-item label="备注" prop="remark">
                <el-input v-model="supplierCost.remark" type="textarea" :disabled="isViews" placeholder="备注">
                </el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="visible = false">取消</el-button>
        <el-button type="primary" @click="dataFormSubmit()" v-if="todo !== 'view'">确定</el-button>
      </span>
    </el-dialog>
    <!-- 查看图片 -->
    <view-image ref="viewPhoto" :limit="4"></view-image>
  </div>
</template>

<script>
import viewImage from '@/views/commonPop/viewImage-fy'
export default {
  data () {
    return {
      visible: false,
      dataForm: {
        id: null,
        orderInfo: {},
        vehiclePlan: {},
        orderGoods: {}
      },
      allWinVehicle: false,
      statusActive: 1,
      dispatchTime: null,
      outDriveTime: null,
      arrivedTime: null,
      loadGoodsTime: null,
      arrivedGiveTime: null,
      successTime: null,
      globalCostConfig: {},
      costInfo: {},
      payCostInfo: {},
      driverRoyalty: {},
      supplierCost: {},
      imgTypeList: {
        // 报关/入仓
        entranceFee: [7050],
        // 高速费
        highFee: [7051],
        // 过磅费
        weighFee: [7052],
        // 装卸费
        loadFee: [7053],
        // 押夜超时费
        overtimeFee: [7054],
        // 提货费
        takeFee: [7055],
        // 加点费
        addFee: [7056],
        // 其他费用
        otherFee: [7057],
        // 加油费
        refuelFee: [7001],
        // 过路费
        passingFee: [7002],
        // 打印费
        printFee: [7003],
        // 维修费
        maintainFee: [7004],
        parkingFee: [7008],
        refuelCardFee: [7006],
        passingCardFee: [7007]
      },
      successTypeList: [6010],
      loadGoodsTypeList: [6011],
      showDriverRoyalty: false,
      chargeType: true,
      royaltyFeeBox: false,
      payFreightFeeExist: false,
      royaltyFreightFeeExist: false,
      wageBase: null,
      todo: 'view',
      costInfoStatus: 1,
      rules: {
        freightFee: this._Valid.canNullinit(['nullOrPrice']),
        entranceFee: this._Valid.canNullinit(['nullOrPrice']),
        highFee: this._Valid.canNullinit(['nullOrPrice']),
        weighFee: this._Valid.canNullinit(['nullOrPrice']),
        loadFee: this._Valid.canNullinit(['nullOrPrice']),
        overtimeFee: this._Valid.canNullinit(['nullOrPrice']),
        takeFee: this._Valid.canNullinit(['nullOrPrice']),
        addFee: this._Valid.canNullinit(['nullOrPrice']),
        parkingFee: this._Valid.canNullinit(['nullOrPrice']),
        otherFee: this._Valid.canNullinit(['nullOrPrice'])
      },
      payRules: {
        freightFee: this._Valid.canNullinit(['nullOrPrice']),
        entranceFee: this._Valid.canNullinit(['nullOrPrice']),
        highFee: this._Valid.canNullinit(['nullOrPrice']),
        weighFee: this._Valid.canNullinit(['nullOrPrice']),
        loadFee: this._Valid.canNullinit(['nullOrPrice']),
        overtimeFee: this._Valid.canNullinit(['nullOrPrice']),
        takeFee: this._Valid.canNullinit(['nullOrPrice']),
        addFee: this._Valid.canNullinit(['nullOrPrice']),
        parkingFee: this._Valid.canNullinit(['nullOrPrice']),
        otherFee: this._Valid.canNullinit(['nullOrPrice']),
        printFee: this._Valid.canNullinit(['nullOrPrice']),
        maintainFee: this._Valid.canNullinit(['nullOrPrice']),
        refuelFee: this._Valid.canNullinit(['nullOrPrice']),
        passingFee: this._Valid.canNullinit(['nullOrPrice']),
        refuelCardFee: this._Valid.canNullinit(['nullOrPrice']),
        passingCardFee: this._Valid.canNullinit(['nullOrPrice'])
      },
      driverRules: {
        freightFee: this._Valid.canNullinit(['nullOrPrice']),
        loadFee: this._Valid.canNullinit(['nullOrPrice']),
        overtimeFee: this._Valid.canNullinit(['nullOrPrice']),
        addFee: this._Valid.canNullinit(['nullOrPrice']),
        extraFee: this._Valid.canNullinit(['nullOrPrice']),
        stayFee: this._Valid.canNullinit(['nullOrPrice'])
      },
      isFreightFeeShow: false
    }
  },
  computed: {
    dictTypeMap: {
      get () { return this.$store.state.common.dictTypeMap }
    },
    isViews () {
      if (this.todo === 'view' || this.costInfoStatus === 2) {
        return true
      } else {
        return false
      }
    }
  },
  created () {
    // 判断是否查询运费
    if (this.newAuth('order:cost:freightFee')) {
      this.isFreightFeeShow = true
    }
  },
  components: {
    viewImage
  },
  methods: {
    clearOldValue () {
      this.statusActive = 1
      this.dispatchTime = null
      this.outDriveTime = null
      this.arrivedTime = null
      this.loadGoodsTime = null
      this.arrivedGiveTime = null
      this.successTime = null
      this.wageBase = null
    },
    init (type, todo, row, listType) {
      this.costInfoStatus = row.costInfo.status
      this.visible = true
      this.royaltyFeeBox = false
      this.todo = todo || 'view'
      this.dataForm = row
      this.chargeType = listType === 1
      this.clearOldValue()
      // this.getGlobalCostConfig()
      this.$http({
        url: this.$http.adornUrl(`/costInfo/auditDetail/${row.orderInfo.id}`),
        method: 'get',
        params: this.$http.adornParams()
      }).then(({ data }) => {
        this.dataForm = data
        this.globalCostConfig = data.driverInfo
        this.costInfo = data.costInfo
        this.payCostInfo = data.payCostInfo
        this.driverRoyalty = data.driverRoyalty
        if (data.supplierCost) {
          this.supplierCost = data.supplierCost
        } else {
          this.supplierCost = {
            freightFee: null,
            otherFee: null,
            payeeUser: null,
            payeePhone: null,
            remark: null
          }
        }
        this.payFreightFeeExist = (data.payCostInfo.freightFee && data.payCostInfo.freightFee > 0)
        if (this.driverRoyalty) {
          this.royaltyFreightFeeExist = (data.driverRoyalty.freightFee && data.driverRoyalty.freightFee > 0)
          let payRequestConfig = this.driverRoyalty.payRequestConfig
          this.royaltyFeeBox = !payRequestConfig.royaltyFeeBox && !payRequestConfig.addFeeBox && !payRequestConfig.overtimeFeeBox
        }
        this.showDriverRoyalty = data.motorcadeName === 'ZYCD'
        let recordList = data.recordList
        this.dispatchTime = data.dispatchTime
        if (recordList) {
          for (let i = 0; i < recordList.length; i++) {
            let record = recordList[i]
            if (record.status === 5) {
              this.outDriveTime = record.operationTime
              this.statusActive = 2
            } else if (record.status === 6) {
              this.arrivedTime = record.operationTime
              this.statusActive = 3
            } else if (record.status === 7) {
              this.loadGoodsTime = record.operationTime
              this.statusActive = 4
            } else if (record.status === 15) {
              this.arrivedGiveTime = record.operationTime
              this.statusActive = 5
            }
          }
        }
        if (data.status === 8) {
          this.successTime = data.successTime
          this.statusActive = 6
        }
        for (const key in this.dictTypeMap.orderStatusMap) {
          if (this.dictTypeMap.orderStatusMap[key].id === data.status) {
            this.dataForm.status = this.dictTypeMap.orderStatusMap[key].name
          }
        }
        this.dataForm.takeGoodsDate = data.takeGoodsDate + ' ' + data.takeGoodsTime
        this.allWinVehicle = this.dataForm.vehiclePlan.allWinVehicle === 1
      })
    },
    // 图片预览
    preview (typeList, id) {
      this.$refs.viewPhoto.init(id, typeList)
    },
    // // 查看照片
    // viewPhoto (row, typeList) {
    //   this.$refs.viewPhoto.init(row.orderInfo.id, typeList)
    // },
    getDefaultValue (value, defaultValue) {
      if (value === undefined || value === null) {
        return defaultValue
      }
      let number = Number(value)
      if (number === undefined || number === null) {
        return defaultValue
      }
      return number
    },
    countMoney (type, field) {
      let info = type === 1 ? this.costInfo : this.payCostInfo
      info.totalMoney = this.getDefaultValue(info.freightFee, 0) +
        this.getDefaultValue(info.entranceFee, 0) +
        this.getDefaultValue(info.parkingFee, 0) +
        this.getDefaultValue(info.highFee, 0) +
        this.getDefaultValue(info.weighFee, 0) +
        this.getDefaultValue(info.loadFee, 0) +
        this.getDefaultValue(info.overtimeFee, 0) +
        this.getDefaultValue(info.takeFee, 0) +
        this.getDefaultValue(info.addFee, 0) +
        this.getDefaultValue(info.otherFee, 0)
      if (type === 2) {
        info.auditTotalMoney = info.totalMoney +
          this.getDefaultValue(info.printFee, 0) +
          this.getDefaultValue(info.maintainFee, 0) +
          this.getDefaultValue(info.refuelFee, 0) +
          this.getDefaultValue(info.passingFee, 0) +
          this.getDefaultValue(info.refuelCardFee, 0) +
          this.getDefaultValue(info.passingCardFee, 0)
        if (this.showDriverRoyalty && field && this.driverRoyalty) {
          // 未支付才可以修改
          if (!this.driverRoyalty.payRequestConfig[field + 'Box']) {
            this.driverRoyalty[field] = info[field]
          }
        }
        let platformPrepayment = this.getDefaultValue(info.refuelCardFee, 0) + this.getDefaultValue(info.passingCardFee, 0)
        if (platformPrepayment > 0) {
          info.platformPrepayment = platformPrepayment
        } else {
          info.platformPrepayment = ''
        }
        let driverPrepayment = this.getDefaultValue(info.weighFee, 0) +
          this.getDefaultValue(info.entranceFee, 0) +
          this.getDefaultValue(info.parkingFee, 0) +
          this.getDefaultValue(info.loadFee, 0) +
          this.getDefaultValue(info.highFee, 0) +
          this.getDefaultValue(info.printFee, 0) +
          this.getDefaultValue(info.maintainFee, 0) +
          this.getDefaultValue(info.refuelFee, 0) +
          this.getDefaultValue(info.passingFee, 0)
        if (driverPrepayment > 0) {
          info.driverPrepayment = driverPrepayment
        } else {
          info.driverPrepayment = ''
        }
      } else {
        // 非自营跳出运费
        if (!this.showDriverRoyalty) {
          // 未支付才可以修改
          if (!this.payCostInfo.payRequestConfig[field + 'Box']) {
            // 共赢车计算应付费用
            if (this.allWinVehicle && this.dataForm.allWinFee > 0 && field) {
              // 费用 = 应收 - 设置比例(值 / 100 * 应收)
              // if (field === 'freightFee') {
              //   if (!this.payFreightFeeExist) {
              //     this.payCostInfo[field] = info[field] - this.calculateRatioValue(info[field], this.dataForm.allWinFee)
              //   }
              // } else {
              // }
              this.payCostInfo[field] = info[field] - this.calculateRatioValue(info[field], this.dataForm.allWinFee)
            } else {
              // 计算应付运费和压夜
              if (this.dataForm.individualFee && this.dataForm.individualFee > 0 && field) {
                // if (field === 'freightFee') {
                //   if (!this.payFreightFeeExist) {
                //     this.payCostInfo[field] = info[field] - this.calculateRatioValue(info[field], this.dataForm.individualFee)
                //   }
                // } else {
                // }
                this.payCostInfo[field] = info[field] - this.calculateRatioValue(info[field], this.dataForm.individualFee)
              }
            }
            this.countMoney(2)
          }
        }
        if (this.showDriverRoyalty && field && this.driverRoyalty && this.royaltyFeeBox) {
          // 客户提成
          if (this.dataForm.royaltyFee) {
            // if (field === 'freightFee') {
            //   if (!this.royaltyFreightFeeExist) {
            //     this.driverRoyalty[field] = (info[field] - this.calculateRatioValue(info[field], this.dataForm.royaltyFee)).toFixed(2)
            //   }
            // } else {
            // }
            this.driverRoyalty[field] = (info[field] - this.calculateRatioValue(info[field], this.dataForm.royaltyFee)).toFixed(2)
          } else {
            // if (field === 'freightFee') {
            //   if (!this.royaltyFreightFeeExist) {
            //     this.driverRoyalty[field] = info[field]
            //   }
            // } else {
            // }
            this.driverRoyalty[field] = info[field]
          }
          this.countBaseMoney()
        }
      }
    },
    countBaseMoney () {
      if (this.royaltyFeeBox) {
        let sumCost = this.getDefaultValue(this.driverRoyalty.freightFee, 0) +
          this.getDefaultValue(this.driverRoyalty.addFee, 0) +
          this.getDefaultValue(this.driverRoyalty.overtimeFee, 0)
        // 司机提成
        if (sumCost > 0 && this.dataForm.driverRoyaltyRatio && this.dataForm.driverRoyaltyRatio > 0) {
          this.driverRoyalty.royaltyFee = this.calculateRatioValue(sumCost, this.dataForm.driverRoyaltyRatio)
        } else {
          this.driverRoyalty.royaltyFee = 0
        }
        this.calculateRoyaltyTotal()
      }
    },
    calculateRatioValue (value, ratio) {
      if (!value) {
        return ''
      }
      let number = value * (ratio / 100)
      if (number <= 0 || number == null) {
        return ''
      }
      // 保留2位小数
      return number.toFixed(2)
    },
    // 计算奖励费用
    calculateReward () {
      let freightFee = this.wageBase
      let payRequestConfig = this.driverRoyalty.payRequestConfig
      if (!payRequestConfig.baseFeeBox) {
        this.driverRoyalty.baseFee = this.calculateRatioValue(freightFee, this.globalCostConfig.baseWageRatio)
      }
      if (!payRequestConfig.meritsFeeBox) {
        this.driverRoyalty.meritsFee = this.calculateRatioValue(freightFee, this.globalCostConfig.meritsWageRatio)
      }
      if (!payRequestConfig.appFeeBox) {
        this.driverRoyalty.appFee = this.calculateRatioValue(freightFee, this.globalCostConfig.useAppReward)
      }
      if (!payRequestConfig.other1FeeBox) {
        this.driverRoyalty.other1Fee = this.calculateRatioValue(freightFee, this.globalCostConfig.other1Reward)
      }
      if (!payRequestConfig.other2FeeBox) {
        this.driverRoyalty.other2Fee = this.calculateRatioValue(freightFee, this.globalCostConfig.other2Reward)
      }
      this.calculateRoyaltyTotal()
    },
    // 合计费用
    calculateRoyaltyTotal () {
      let royalty = this.driverRoyalty
      let royaltyTotal = this.getDefaultValue(royalty.royaltyFee, 0) +
        this.getDefaultValue(royalty.loadFee, 0) +
        this.getDefaultValue(royalty.extraFee, 0) +
        this.getDefaultValue(royalty.stayFee, 0) +
        this.getDefaultValue(royalty.baseFee, 0) +
        this.getDefaultValue(royalty.meritsFee, 0) +
        this.getDefaultValue(royalty.appFee, 0) +
        this.getDefaultValue(royalty.other1Fee, 0) +
        this.getDefaultValue(royalty.other2Fee, 0)
      royalty.payableAmount = this.getDefaultValue(royaltyTotal, 0).toFixed(2)
    },
    getGlobalCostConfig () {
      this.globalCostConfig = {}
      this.$http({
        url: this.$http.adornUrl('/dict/findInfoList'),
        method: 'get',
        params: this.$http.adornParams({
          typeCode: 'royalty_config'
        })
      }).then(({ data }) => {
        for (let i = 0; i < data.length; i++) {
          this.globalCostConfig[data[i].code] = Number(data[i].name)
        }
      })
    },
    // 表单提交
    dataFormSubmit () {
      let _this = this
      let ref = this.$refs['dataForm']
      if (ref) {
        ref.validate((valid) => {
          if (valid) {
            _this.submitDataFrom()
          }
        })
      } else {
        _this.submitDataFrom()
      }
    },
    submitDataFrom () {
      this.$http({
        url: this.$http.adornUrl(`/costInfo/update/auditCost`),
        method: 'POST',
        data: this.$http.adornData({
          'id': this.dataForm.id,
          'costInfo': this.costInfo,
          'payCostInfo': this.payCostInfo,
          'driverRoyalty': this.driverRoyalty,
          'supplierCost': this.supplierCost,
          'customerId': this.dataForm.customerId
        })
      }).then(({ data }) => {
        if (data) {
          this.$message({
            message: '操作成功',
            type: 'success',
            duration: 1000,
            onClose: () => {
              this.visible = false
              this.$emit('refreshDataList')
            }
          })
        } else {
          this.$message.error('操作失败')
        }
      })
    }
  }
}
</script>
  <style lang="less">
  .cost-modi-pop {
    .el-form-item {
      margin-bottom: 0px;
    }
    .el-dialog {
      margin-top: 0 !important;
      .el-input__inner {
        height: 24px !important;
        font-size: 12px; /* 输入框的字号 */
      }
      .el-dialog__header {
        padding: 0px 0px 0px;
      }
      .el-form-item__label {
        font-size: 12px; /* label 的字号 */
      }
    }
    .el-dialog__body {
      padding: 0;
      max-height: 960px;
      overflow: auto;
    }
    .el-dialog__footer {
      padding: 0px 20px 10px;
      .el-button {
        height: 32px;
        line-height: 0;
      }
    }
    .modularBox {
      // box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px, rgba(0, 0, 0, 0.12) 0px 0px 6px;
      box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 3px, rgba(0, 0, 0, 0.08) 0px 0px 3px;
      //margin-left: 20px;
      //margin-right: 20px;
      .el-col {
        padding-right: 0 !important;
      }
    }
    .modularBox1 {
      padding-right: 40px;
      padding-top: 5px;
      padding-bottom: 5px;
    }
    .modularBox2 {
      //padding-top: 5px;
      //padding-bottom: 5px;
      padding-right: 40px;
      margin-bottom: 2px;
      .addressBtn {
        width: 100%;
        padding: 9px 0px;
        text-align: center
      }
      .deleteBtn {
        width: 100%;
        height: 40px;
        padding-top: 5px;
        text-align: right;
      }
    }
    .modularBox3 {
      padding: 25px;
      .carBox {
        width: 320px;
        margin-right: 20px;
        // box-shadow: rgba(0, 0, 0, 0.12) 0px 2px 4px, rgba(0, 0, 0, 0.12) 0px 0px 6px;
        box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 3px, rgba(0, 0, 0, 0.08) 0px 0px 3px;
        text-align: center;
        cursor: pointer;
      }
    }
    .modularBox4 {
      padding: 25px;
    }
    .modularLabel {
      margin-bottom: 0px;
      //padding-left: 20px;
      margin-top: 15px;
      span.separate {
        display: inline-block;
        width: 5px;
        margin-right: 10px;
        background: #17B3A3;
        color: transparent;
        height: 20px;
        line-height: 20px;
      }
      label {
        font-size: 14px;
        font-weight: bold;
      }
    }
    .modularLabel2 {
      margin-top: 0px;
      line-height: 30px;
      height: 30px;
      label {
        min-width: 120px;
        float: left;
      }
    }
    .footer {
      margin-left: 20px;
      margin-right: 20px;
      margin-bottom: 30px;
      text-align: right;
      padding: 25px;
      .explain {
        color: #17B3A3 !important
      }
    }
    .common-pop {
      .el-table__header-wrapper {
        font-size: 8px;
      }
      .el-table__body-wrapper {
        font-size: 8px;
      }
      .cell {
        padding: 0 !important;
      }
      .el-dialog {
        margin-top: 0px !important;
        margin: 0 auto;
        top: 50%;
        transform: translate(-0, -50%);
        .el-dialog__header {
          padding: 10px;
        }
        .el-dialog__body {
          padding: 10px;
        }
        .el-dialog__footer {
          padding: 10px;
          .el-button {
            height: 32px;
            line-height: 0;
          }
        }
      }
    }
    .el-step__title {
      font-size: 14px; /* 调整为你希望的字体大小 */
      line-height: 18px;
    }
  }
  </style>
<style lang="less">
.item .el-form-item__label{
  color: red;
}
</style>
